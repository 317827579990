
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { toggleMenu, unexpandMenuItems, deactivateCurrentItem } from '../store/actions'
import MenuItem from './MenuItem.vue';

interface MenuMethods {
    closeMenu(): void
    deactivateCurrentItem(): void
    filterChildren(children: any[]): any[]
}

interface MenuComputed {
	menu: IMenuItem
    showMenu: boolean
    activeMenuItem: IMenuItem
    currentLevel: number
    topLinks: []
}

export default Vue.extend<{}, MenuMethods, MenuComputed, {}>({
	name: 'Menu',
    computed: {
        menu() {
            console.log(this.$store.getters.menu)
            return this.$store.getters.menu
        },
        showMenu() {
            return this.$store.getters.showMenu
        },
        activeMenuItem() {
            return this.$store.getters.activeMenuItem
        },
        currentLevel(){
            return this.$store.getters.menuBreadCrumbs.length
        },
        topLinks(){
            return this.$store.getters.topLinks
        }
    },
    methods: {
        closeMenu() {
            this.$store.dispatch(toggleMenu({
                open: false
            }))
			const html = document.querySelector('html');
            html?.removeAttribute('menu-open');
		},
        deactivateCurrentItem() {
            this.$store.dispatch(deactivateCurrentItem(undefined))
        },
        filterChildren(children) {
            const filteredChildren = children.filter((child) => {
                return child.isExpanded === true || child.level > this.currentLevel
            })
            return filteredChildren;
        }
    },
    mounted() {
            window.addEventListener('click', (e) => {
                const appRef = this.$refs.menu as HTMLElement;
                const target = e.target as HTMLElement;
                if((appRef?.contains(target)) || target.dataset?.ref === 'menu-button' || target.hasAttribute('data-ignore-close')) return;
                if(this.showMenu) this.closeMenu();
            })
	},
    components: {
        MenuItem
    }
})
