
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { expandMenuItem } from '../store/actions'

interface MenuItemProps {
	item: IMenuItem
}

interface MenuItemComputed {
    currentLevel: number
}

interface MenuItemMethods {
    expandMenuItem(item: IMenuItem): void
    filterChildren(children: any[]): any[]
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>({
	name: 'MenuItem',
    props: {
		item: {},
	},
    computed: {
        currentLevel(){
            return this.$store.getters.menuBreadCrumbs.length
        },
    },
    methods: {
        expandMenuItem(item) {
            this.$store.dispatch(expandMenuItem(item))
        },
        filterChildren(children) {
            const filteredChildren = children.filter((child) => {
                return child.isExpanded === true || child.level > this.currentLevel
            })
            return filteredChildren;
        }
    }
})
