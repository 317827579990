
import Vue from 'vue'

interface SearchFormComputed {
    showSearch: boolean
    searchPageUri: string
}

interface SearchFormMethods {
    toggleSearch(): void
}

export default Vue.extend<{}, SearchFormMethods, SearchFormComputed, {}>({
	name: 'SearchForm',
    computed: {
        showSearch() {
            return this.$store.getters.showSearch;
        },
        searchPageUri() {
            return this.$store.getters.searchPageUri;
        }
    },
    methods: {
        toggleSearch() {
            this.$store.dispatch({
                type: 'toggleSearch',
            })
        },
    }
})
