import { IMenuItem, IState} from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'getChildren' | 'expandMenuItem' | 'toggleMenu' | 'toggleLanguages' | 'toggleSearch' | 'unexpandMenuItems' | 'deactivateCurrentItem'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}
export interface IGetChildrenPayload {
	id: string
	level: number
}
export interface IAddMenuItemsPayload {
	menuItem: IMenuItem,
	items: IMenuItem[]
}
export interface IToggleMenuItem {
	menuItem?: IMenuItem
	open: boolean
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})
export const getChildren: ActionCreator<IGetChildrenPayload> = payload => ({
	type: 'getChildren',
	payload: payload

})
export const toggleMenu: ActionCreator<IToggleMenuItem> = (payload) => ({
	type: 'toggleMenu',
	payload: payload
})
export const expandMenuItem: ActionCreator<IMenuItem> = menuItem => ({
	type: 'expandMenuItem',
	payload: menuItem
})
export const unexpandMenuItems: ActionCreator<undefined> = () => ({
	type: 'unexpandMenuItems',
	payload: undefined
})
export const deactivateCurrentItem: ActionCreator<undefined> = () => ({
	type: 'deactivateCurrentItem',
	payload: undefined
})
// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}
const getChildrenAction: ActionHandler<IMenuItem> = ({ commit, state }, { payload }) => {
	api.getMenuItems(state.endpoints.getChildren, {id: payload.id, level: payload.level})
		.then(res => {
			commit({
				type: 'addMenuItems',
				payload: {menuItem: payload, items: res}
			})
		})
}
const expandMenuItemAction: ActionHandler<IMenuItem> = ({ commit, dispatch, state }, { payload }) => {
	commit({
		type: 'expandMenuItem',
		payload: payload
	})

	if(payload.children.length < 1) {
		dispatch(getChildren(payload))
	}
}
const unexpandMenuItemsAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	commit({
		type: 'unexpandMenuItems',
		payload: payload
	})
}
const toggleMenuAction: ActionHandler<IToggleMenuItem> = ({ commit, dispatch, state }, { payload }) => {
	commit({
		type: 'toggleMenu',
		payload: payload
	})
}
const toggleLanguagesAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'toggleLanguages',
	})
}
const toggleSearchAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'toggleSearch',
	})
}
const deactivateCurrentItemAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	commit({
		type: 'deactivateCurrentItem',
		payload: payload
	})
}
const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	getChildren: getChildrenAction,
	toggleMenu: toggleMenuAction,
	toggleLanguages: toggleLanguagesAction,
	toggleSearch: toggleSearchAction,
	expandMenuItem: expandMenuItemAction,
	unexpandMenuItems: unexpandMenuItemsAction,
	deactivateCurrentItem: deactivateCurrentItemAction
}

export default actions;
