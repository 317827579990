import { Getter } from "vuex";
import { IMenuItem, IState } from './state';

const MAX_ITERATIONS = 1000;

const checkExpanded = (children?: IMenuItem[], list?: IMenuItem[], iteration?: number) => {
	if(iteration && iteration > MAX_ITERATIONS || !children) return
	const breadCrumbs : IMenuItem[] = list ? list : []
	let i = iteration ? iteration : 0;

	children.some((child: IMenuItem) => {
		if (child.isExpanded) {
			breadCrumbs.push(child)
			if (child.hasChildren) checkExpanded(child.children, breadCrumbs, ++i)
			return true;
		}
	})
	return breadCrumbs
}

const topLinks: Getter<IState, IState> = (state) => state.topLinks;
const menu: Getter<IState, IState> = (state) => state.menu;
const menuBreadCrumbs: Getter<IState, IState> = (state, getters) => {
	let activeItem = getters.activeMenuItem;
	if(activeItem) {
		const expandedChildren = checkExpanded(activeItem.children)
		expandedChildren?.unshift(activeItem)
		if(expandedChildren) {
			state.menuBreadCrumbs = expandedChildren
		}
	}
	return state.menuBreadCrumbs
};
const logo: Getter<IState, IState> = (state) => state.logo;
const startPage: Getter<IState, IState> = (state) => state.startPage;
const searchPageUri: Getter<IState, IState> = (state) => state.endpoints.searchPageUri;
const showLanguages: Getter<IState, IState> = (state) => state.showLanguages;
const showMenu: Getter<IState, IState> = (state) => state.showMenu;
const showSearch: Getter<IState, IState> = (state) => state.showSearch;
const mobile: Getter<IState, IState> = (state) => {
	return Math.max(
		document.body.scrollWidth,
		document.documentElement.scrollWidth,
		document.body.offsetWidth,
		document.documentElement.offsetWidth,
		document.documentElement.clientWidth
	  ) < 1024;
};
const activeMenuItem: Getter<IState, IState> = (state) => {
	const activeItem = state.menu.find(menuItem => menuItem.isExpanded);
	return activeItem;
}
export default {
	topLinks,
	menu,
	logo,
	startPage,
	searchPageUri,
	showLanguages,
	showSearch,
	showMenu,
	activeMenuItem,
	menuBreadCrumbs,
	mobile
}
