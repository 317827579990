
import Vue from 'vue'
import { ILogo } from '../store/state'

interface LogoComputed {
	logo: ILogo
    startPage: Object
}

export default Vue.extend<{}, {}, LogoComputed, {}>({
	name: 'Logo',
    computed: {
        startPage() {
			return this.$store.getters.startPage;
		},
        logo() {
			return this.$store.getters.logo;
		},
    },
})
