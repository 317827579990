
import Vue from 'vue'

interface SearchButtonComputed {
    showSearch: boolean
    mobile: boolean
}

interface SearchButtonMethods {
    toggleSearch(): void
    buttonString(mobile: boolean, returnString: string): string
}

export default Vue.extend<{}, SearchButtonMethods, SearchButtonComputed, {}>({
	name: 'SearchButton',
    computed: {
        showSearch() {
            return this.$store.getters.showSearch
        },
        mobile() {
            return this.$store.getters.mobile
        }
    },
    methods: {
        toggleSearch() {
            this.$store.dispatch({
                type: 'toggleSearch',
            })
        },
        buttonString(mobile, returnString) {
            if (mobile) return ''
            return returnString
        }
    },
})
