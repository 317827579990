
import Vue from 'vue'
import { toggleMenu, unexpandMenuItems } from '../store/actions';
import { IMenuItem } from '../store/state'
import MenuItem from './MenuItem.vue';

interface MenuButtonMethods {
    openMenu(): void
}

interface MenuButtonComputed {
	menu: IMenuItem
	showMenu: boolean
    mobile: boolean
}

export default Vue.extend<{}, MenuButtonMethods, MenuButtonComputed, {}>({
	name: 'MenuButton',
    computed: {
        menu() {
            return this.$store.getters.menu
        },
        showMenu() {
            return this.$store.getters.showMenu
        },
        mobile() {
            return this.$store.getters.mobile
        },
    },
    methods: {
        openMenu() {
            const html = document.querySelector('html');
            this.$store.dispatch(toggleMenu({
                open: !this.showMenu
            }))
            if(!this.showMenu) {
                html?.removeAttribute('menu-open');
            } else {
                html?.setAttribute('menu-open', '');
            }
        }
    },
    components: {
        MenuItem
    }
})
