export interface IState {
	topLinks: ITopLinks
	menu: IMenuItem[]
	logo: ILogo
	startPage: object
	showLanguages: boolean
	showMenu: boolean
	showSearch: boolean
	endpoints: {
		getChildren: string
		searchPageUri: string
	}
	menuBreadCrumbs: IMenuItem[]
}

export interface ILogo {
	uri: string
	altText: string
}

export interface ILanguageItem {
	uri: string
	name: string
}

export interface IMenuItem {
	name: string
	uri: string
	id: string
	isCurrent: boolean
	isExpanded: boolean
	hasChildren: boolean
	level: number
	children: IMenuItem[]
}

export interface ITopLinks {
	translateLink: string
	loginLink: string
}

const defultState: IState = {
	topLinks: {
		translateLink: '',
		loginLink: '',
	},
	menu: [],
	logo: {
		uri: '',
		altText: ''
	},
	startPage: {},
	showMenu: false,
	showLanguages: false,
	showSearch: false,
	endpoints: {
		getChildren: '',
		searchPageUri: ''
	},
	menuBreadCrumbs: []
}

export default defultState;
