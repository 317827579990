
import Vue from 'vue'
import Logo from './components/Logo.vue';
import SearchButton from './components/SearchButton.vue'
import Menu from './components/Menu.vue';
import MenuButton from './components/MenuButton.vue';
import SearchForm from './components/SearchForm.vue';
import { FocusTrap } from 'focus-trap-vue'

Vue.component('FocusTrap', FocusTrap);

interface AppComputed {
	showMenu: boolean,
	topLinks: {},
	mobile: boolean
}

export default Vue.extend<{}, {}, AppComputed, {}>({
	name: 'app',
	computed: {
		showMenu() {
			return this.$store.getters.showMenu
		},
		topLinks() {
			return this.$store.getters.topLinks
		},
		 mobile() {
            return this.$store.getters.mobile
        }
	},
	components: {
		Logo,
		SearchButton,
		Menu,
		MenuButton,
		SearchForm
	}
})
