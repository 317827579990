import Vue from 'vue';
import { MutationTree, Mutation } from 'vuex';
import { IState, IMenuItem } from './state';
import { ActionPayload, IAddMenuItemsPayload, IToggleMenuItem } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}
const addMenuItemsMutation: MutationHandler<IAddMenuItemsPayload> = (state, { payload }) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, { children: payload.items})
	}
}
const toggleMenuMutation: MutationHandler<IToggleMenuItem> = (state, {payload}) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, {isExpanded: payload.open})
		state.menuBreadCrumbs.push(payload.menuItem)
	}
	state.showMenu = payload.open
}
const toggleLanguagesMutation: MutationHandler<IState> = (state, {payload}) => {
	state.showLanguages = !state.showLanguages
}
const toggleSearchMutation: MutationHandler<IState> = (state, { payload }) => {
	state.showSearch = !state.showSearch;
}
const expandMenuItemMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded, isCurrent: !payload.isCurrent})
	state.menuBreadCrumbs.push(payload)
}
const unexpandMenuItemsMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	state.menu.forEach(menuItem => {
		Object.assign(menuItem, {isExpanded: false})
	})
}
const deactivateCurrentItemMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	const lastItem = state.menuBreadCrumbs.length - 1;
	Object.assign(state.menuBreadCrumbs[lastItem], {isExpanded: !state.menuBreadCrumbs[lastItem].isExpanded})
	state.menuBreadCrumbs.pop()
}
const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	addMenuItems: addMenuItemsMutation,
	expandMenuItem: expandMenuItemMutation,
	toggleSearch: toggleSearchMutation,
	unexpandMenuItems: unexpandMenuItemsMutation,
	toggleLanguages: toggleLanguagesMutation,
	toggleMenu: toggleMenuMutation,
	deactivateCurrentItem: deactivateCurrentItemMutation
}

export default mutations
